import React from 'react';
import PropTypes from 'prop-types';
import { Buffer } from 'buffer';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import ClickIdInfo from './ClickIdInfo';

const clickIDArbitrageVersion = 1;
const maxBrId = 1000;
const maxOsId = 1000;
const countryRegexp = /[A-Z]{2}/;
const testVersion = 255;
const currentVersion = 1;

class Info extends React.Component {
  constructor(props) {
    super(props);

    this.re = {
      newClickId: /^[a-zA-Z0-9\-_]{39}$/,
      clickId: /^[a-zA-Z0-9\-_]{32}$/,
      shortClickId: /^[a-zA-Z0-9\-_]{19}$/,
      plClickId: /^([A-Z0-9]{2})\.([0-9]+)\.([0-9]+)\.([0-9]+)\.([0-9]+)\.([0-9]+)$/g,
      psClickId: /^([0-9]+)\.([0-9]+)\.([A-Z0-9]{2})\.([0-9]+)\.([0-9]+)(\.([0-9]+))?$/g
    };
  }
  parseClickId(id) {
    try {
      const buf = Buffer.from(id, 'base64');

      const version = buf.readUInt8(0);
      const ts = buf.readUInt32LE(1);
      const plId = buf.readUInt32LE(5);
      const prId = buf.readUInt32LE(9);
      const country = buf.slice(13, 15).toString('utf8');
      const osId = buf.readUInt16LE(15);
      const brId = buf.readUInt16LE(17);
      let aprId = 0;
      if (version >= clickIDArbitrageVersion) {
        aprId = buf.readUInt32LE(19);
      }
      if (version > currentVersion && version != testVersion) {
        return null;
      }
      if (!country.match(countryRegexp)) {
        return null;
      }
      if (brId > maxBrId) {
        return null;
      }
      if (osId > maxOsId) {
        return null;
      }
      return {
        click_id: id,
        version: version,
        timestamp: ts,
        pl_id: plId,
        pr_id: prId,
        country: country,
        os_id: osId,
        br_id: brId,
        a_pr_id: aprId
      };
    } catch {
      return null;
    }
  }

  parseShortClickId(id) {
    try {
      const buf = Buffer.from(id, 'base64');

      const plId = buf.readUInt32LE(0);
      const prId = buf.readUInt32LE(4);
      const country = buf.slice(8, 10).toString('utf8');
      const osId = buf.readUInt16LE(10);
      const brId = buf.readUInt16LE(12);

      return {
        pl_id: plId,
        pr_id: prId,
        country: country,
        os_id: osId,
        br_id: brId
      };
    } catch {
      return null;
    }
  }

  processClickId(id) {
    if (this.re.newClickId.test(id)) {
      return [this.parseClickId(id.slice(7))];
    }
    if (this.re.clickId.test(id)) {
      return [this.parseClickId(id)];
    }
    let slicedClicks = [];
    if (id.length > 32) {
      for (let i = 0; i < id.length; i++) {
        let slicedId = id.slice(i, i + 32);
        if (this.re.clickId.test(slicedId)) {
          let slicedClickId = this.parseClickId(slicedId);
          if (slicedClickId != null) {
            slicedClicks.push(slicedClickId);
          }
        }
      }
      if (slicedClicks.length == 0) {
        slicedClicks.push(null);
      }
      return slicedClicks;
    }
    if (this.re.shortClickId.test(id)) {
      return [this.parseShortClickId(id)];
    }
    const v1 = [...id.matchAll(this.re.plClickId)];
    if (v1.length > 0) {
      return [
        {
          country: v1[0][1],
          pl_id: v1[0][2],
          pr_id: v1[0][3],
          os_id: v1[0][4],
          br_id: v1[0][5]
        }
      ];
    }

    const v2 = [...id.matchAll(this.re.psClickId)];
    if (v2.length > 0) {
      return [
        {
          country: v2[0][3],
          ps_id: v2[0][1],
          pr_id: v2[0][2],
          os_id: v2[0][4],
          br_id: v2[0][5]
        }
      ];
    }

    return [null];
  }
  render() {
    return (
      <Col>
        <Table striped hover size="sm">
          <thead>
            <tr>
              <th>Click id</th>
              <th>Timestamp</th>
              <th>Platform id</th>
              <th>Promo id</th>
              <th>Country</th>
              <th>Os</th>
              <th>Browser</th>
              <th>Version</th>
              <th>Arbitrage promo id</th>
            </tr>
          </thead>
          <tbody>
            {this.props.items.map((v) => {
              return <ClickIdInfo key={v} url={v} data={this.processClickId(v)} />;
            })}
          </tbody>
        </Table>
      </Col>
    );
  }
}

Info.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string)
};

export default Info;
