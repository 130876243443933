import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Buffer } from 'buffer';

class ClickIdInfo extends React.Component {
  constructor(props) {
    super(props);

    this.os = {
      1: { name: 'Bot', version: '' },
      2: { name: 'Android Phone', version: 'Other' },
      3: { name: 'Android Tablet', version: 'Other' },
      4: { name: 'Windows Mobile', version: '' },
      5: { name: 'Windows Phone', version: '' },
      6: { name: 'Windows Tablet', version: '' },
      7: { name: 'XBox', version: '' },
      8: { name: 'Nintendo', version: '' },
      9: { name: 'PSP', version: '' },
      10: { name: 'Playstation', version: '' },
      11: { name: 'FirefoxOS Tablet', version: '' },
      12: { name: 'FirefoxOS Phone', version: '' },
      13: { name: 'Bada', version: '' },
      14: { name: 'Palm', version: '' },
      15: { name: 'BlackBerry', version: '' },
      16: { name: 'Symbian', version: '' },
      17: { name: 'Featured Phone', version: '' },
      18: { name: 'iPhone', version: '' },
      19: { name: 'iPad', version: '' },
      20: { name: 'iPod', version: '' },
      21: { name: 'OS X', version: 'Other' },
      22: { name: 'Unix', version: '' },
      23: { name: 'Windows', version: '10' },
      24: { name: 'Windows', version: '8' },
      25: { name: 'Windows', version: '7' },
      26: { name: 'Windows', version: 'XP' },
      27: { name: 'Windows', version: 'Vista' },
      28: { name: 'Windows', version: 'Other' },
      29: { name: 'Other', version: '' },
      30: { name: 'Android Phone', version: '2' },
      31: { name: 'Android Phone', version: '3' },
      32: { name: 'Android Phone', version: '4' },
      33: { name: 'Android Phone', version: '5' },
      34: { name: 'Android Phone', version: '6' },
      35: { name: 'Android Tablet', version: '2' },
      36: { name: 'Android Tablet', version: '3' },
      37: { name: 'Android Tablet', version: '4' },
      38: { name: 'Android Tablet', version: '5' },
      39: { name: 'Android Tablet', version: '6' },
      40: { name: 'Android Phone', version: '7' },
      41: { name: 'Android Tablet', version: '7' },
      42: { name: 'Android Phone', version: '8' },
      43: { name: 'Android Tablet', version: '8' },
      44: { name: 'OS X', version: 'Snow Leopard' },
      45: { name: 'OS X', version: 'Lion' },
      46: { name: 'OS X', version: 'Mountain Lion' },
      47: { name: 'OS X', version: 'Mavericks' },
      48: { name: 'OS X', version: 'Yosemite' },
      49: { name: 'OS X', version: 'El Capitan' },
      50: { name: 'OS X', version: 'Sierra' },
      51: { name: 'OS X', version: 'High Sierra' },
      52: { name: 'OS X', version: 'Mojave' },
      53: { name: 'Android Phone', version: '9' },
      54: { name: 'Android Tablet', version: '9' },
      55: { name: 'Android Phone', version: '10' },
      56: { name: 'Android Tablet', version: '10' },
      57: { name: 'OS X', version: 'Catalina' }
    };

    this.browsers = {
      1: { name: 'Opera', version: '32' },
      2: { name: 'Opera', version: '33' },
      3: { name: 'Opera', version: '34' },
      4: { name: 'Opera', version: '35' },
      5: { name: 'Opera', version: '36' },
      6: { name: 'Opera' },
      7: { name: 'Firefox', version: '42' },
      8: { name: 'Firefox', version: '43' },
      9: { name: 'Firefox', version: '44' },
      10: { name: 'Firefox', version: '45' },
      11: { name: 'Firefox', version: '46' },
      12: { name: 'Firefox' },
      13: { name: 'Chrome', version: '46' },
      14: { name: 'Chrome', version: '47' },
      15: { name: 'Chrome', version: '48' },
      16: { name: 'Chrome', version: '49' },
      17: { name: 'Chrome', version: '50' },
      18: { name: 'Chrome' },
      19: { name: 'Safari', version: '5' },
      20: { name: 'Safari', version: '6' },
      21: { name: 'Safari', version: '7' },
      22: { name: 'Safari', version: '8' },
      23: { name: 'Safari', version: '9' },
      24: { name: 'Safari', version: '10' },
      25: { name: 'Safari' },
      26: { name: 'Yandex', version: '12' },
      27: { name: 'Yandex', version: '13' },
      28: { name: 'Yandex', version: '14' },
      29: { name: 'Yandex', version: '15' },
      30: { name: 'Yandex', version: '16' },
      31: { name: 'Yandex' },
      32: { name: 'IE', version: '6' },
      33: { name: 'IE', version: '7' },
      34: { name: 'IE', version: '8' },
      35: { name: 'IE', version: '9' },
      36: { name: 'IE', version: '10' },
      37: { name: 'IE' },
      38: { name: 'Other' },
      39: { name: 'UC Browser' },
      40: { name: 'Edge' },
      41: { name: 'Android' },
      42: { name: 'Samsung' }
    };
  }

  getOs(id) {
    const v = this.os[id];
    if (!v) {
      return id;
    }

    return `${id} - ${v.name}${v.version ? ` ${v.version}` : ''}`;
  }

  getTime(ts) {
    if (!ts) {
      return '';
    }

    const v = moment(ts * 1000);
    if (!v.isValid()) {
      return '';
    }

    return v.format('YYYY-MM-DD HH:mm:ss');
  }

  getBrowser(id) {
    const v = this.browsers[id];
    if (!v) {
      return id;
    }

    return `${id} - ${v.name}${v.version ? ` ${v.version}` : ''}`;
  }

  formatUrl(url) {
    let textWithColor = [];
    let idNumber = -1;
    let prevIndex = 0;
    const lengthClickId = 32;
    if (url.length > lengthClickId) {
      this.props.data.forEach((item) => {
        idNumber = url.indexOf(item.click_id, prevIndex);
        if (idNumber !== -1) {
          const start = url.slice(prevIndex, idNumber);
          textWithColor.push({ text: start, color: 'black' });
          const clickIdColor = url.slice(idNumber, idNumber + lengthClickId);
          textWithColor.push({ text: clickIdColor, color: 'red' });
          prevIndex = idNumber + lengthClickId;
        }
      });
      if (prevIndex < url.length) {
        const remainingUrl = url.slice(prevIndex);
        textWithColor.push({ text: remainingUrl, color: 'black' });
      }
    }
    return textWithColor;
  }

  decodePathParams(data, key) {
    try {
      const src = Buffer.from(data, 'base64');
      const dest = [];
      const keyLen = key.length;
      for (let i = 0; i < src.length; i++) {
        const b = src[i] ^ (i + key[i % keyLen]);
        dest.push(b);
      }
      return Buffer.from(dest).toString();
    } catch (error) {
      return error.message;
    }
  }

  parseUrlData() {
    let regWordWrap = /&/g;
    const hashEncodeKey = [12, 134, 123, 235];
    const CryptoJS = require('crypto-js');
    let query = '';
    let indexClickId = -1;
    if (this.props.data.length == 1 && this.props.data[0] != null) {
      indexClickId = this.props.url.indexOf(this.props.data[0].click_id);
    }
    if (indexClickId !== -1) {
      query = this.props.url.slice(indexClickId + 32);
      let result = this.decodePathParams(query, hashEncodeKey);
      let checksum = result.match(/(&checksum=([a-f,0-9]{10}))/);
      if (checksum != undefined) {
        result = result.replace(/(&checksum=[a-f,0-9]{10})/, '');
        let checksumMD5 = CryptoJS.MD5(result);
        checksumMD5 = checksumMD5.toString().substring(0, 10);
        if (checksum[2] == checksumMD5) {
          result = result.replace(regWordWrap, '&<wbr />');
          return result;
        } else {
          return '';
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  generateUrlPrId(pr_id) {
    return (
      'https://crm.bigdeal.agency/#/campaigns/entities/list?filter=%7B%22where%22:%7B%22pr_id%22:' + pr_id + '%7D%7D'
    );
  }
  generateUrlPlId(pl_id) {
    return 'https://crm.bigdeal.agency/#/platforms/' + pl_id + '/edit';
  }

  render() {
    const urlData = this.parseUrlData();
    return (
      <>
        {this.props.data.length && this.props.data[0] ? (
          <td colSpan={9}>
            {this.formatUrl(this.props.url).map((item, index) => (
              <span key={index} style={{ color: item.color }} dangerouslySetInnerHTML={{ __html: item.text }}></span>
            ))}
          </td>
        ) : (
          <></>
        )}
        {this.props.data.map((item, i) => {
          return (
            <>
              <tr key={i}>
                <>
                  {item ? (
                    <>
                      <td style={{ color: 'red' }}>{item.click_id || this.props.clickId}</td>
                      <td>{this.getTime(item.timestamp)}</td>
                      <td>
                        {' '}
                        <a href={this.generateUrlPlId(item.pl_id)} target="_blank" rel="noreferrer">
                          {item.pl_id}
                        </a>
                      </td>
                      <td>
                        <a href={this.generateUrlPrId(item.pr_id)} target="_blank" rel="noreferrer">
                          {item.pr_id}
                        </a>
                      </td>
                      <td>{item.country}</td>
                      <td>{this.getOs(item.os_id)}</td>
                      <td>{this.getBrowser(item.br_id)}</td>
                      <td>{item.version}</td>
                      <td>{item.a_pr_id}</td>
                    </>
                  ) : (
                    <>
                      <td>{this.props.url}</td>
                      <td colSpan="6">Invalid click id</td>
                    </>
                  )}
                </>
              </tr>
            </>
          );
        })}
        <>
          {urlData ? (
            <tr>
              <td colSpan={9} dangerouslySetInnerHTML={{ __html: urlData }}></td>
            </tr>
          ) : (
            <></>
          )}
        </>
      </>
    );
  }
}

ClickIdInfo.propTypes = {
  clickId: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      click_id: PropTypes.string,
      timestamp: PropTypes.number,
      pl_id: PropTypes.number,
      pr_id: PropTypes.number,
      country: PropTypes.string,
      os_id: PropTypes.number,
      br_id: PropTypes.number,
      version: PropTypes.number,
      a_pr_id: PropTypes.number
    })
  ),
  url: PropTypes.string,
  arr: PropTypes.string,
  start: PropTypes.string
};

export default ClickIdInfo;
