import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ClickIdForm from './components/ClickIdForm';
import Info from './components/Info';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: []
    };

    this.onClickIdChange = this.onClickIdChange.bind(this);
  }

  getItems(data = '') {
    return Object.keys(
      data
        .trim()
        .split('\n')
        .reduce((v, values) => {
          values
            .trim()
            .split(',')
            .forEach((b) => {
              b = b.trim();
              if (b.length > 0) {
                v[b] = true;
              }
            });

          return v;
        }, {})
    );
  }

  onClickIdChange(event) {
    event.preventDefault();

    this.setState({
      items: this.getItems(event.target.value)
    });
  }

  render() {
    return (
      <Container>
        <Row>
          <Col>
            <h5>Put click id</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <ClickIdForm onClickIdChange={this.onClickIdChange} />
          </Col>
        </Row>
        <Info items={this.state.items} />
      </Container>
    );
  }
}

export default App;
