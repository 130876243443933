import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import React from 'react';

function ClickIdForm(props) {
  return (
    <Form>
      <Form.Control as="textarea" rows="3" name="click_id" onChange={props.onClickIdChange} />
    </Form>
  );
}

ClickIdForm.propTypes = {
  onClickIdChange: PropTypes.func
};

export default ClickIdForm;
